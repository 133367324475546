import React, { useState } from "react";
import "./styles.css";
import Img1 from "../../img/img1.jpg";
import emailjs from "@emailjs/browser";

const HomePage = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: name,
      phone: phone,
      subject: subject,
    };

    emailjs
      .send(
        "service_55zsc8s", // Substitua pelo seu Service ID
        "template_hs5m6u5", // Substitua pelo seu Template ID
        templateParams,
        "Sgu_2614_Iq2h_N2I" // Substitua pela sua Public Key
      )
      .then(
        (response) => {
          alert("E-mail enviado com sucesso!");
          setName("");
          setPhone("");
          setSubject("");
        },
        (error) => {
          alert("Falha ao enviar o e-mail, tente novamente.");
          console.error("Erro: ", error);
        }
      );
  };

  return (
    <div className="container">
      <div className="image-container">
        <img src={Img1} alt="AL Materiais de Construção" />
      </div>
      <br />
      <h1>Contato</h1>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nome:</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Seu nome"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Número de Celular:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            placeholder="Seu número de celular"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="subject">Assunto:</label>
          <input
            type="text"
            id="subject"
            name="subject"
            placeholder="Assunto"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="submit-button">
          Enviar
        </button>
      </form>
    </div>
  );
};

export default HomePage;
